import { Avatar, Box, useMediaQuery, Popover, List, useTheme } from "@mui/material";
import React, { useState } from "react";
import { DeleteForeverOutlined, Phone } from "@mui/icons-material";
import api from "../../axios";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import EditEmployee from "./EditStaffModal";
import ChangePassEmployee from "./ChangePassModal";
import { tokens } from "../../theme";

function StaffList({ updateData, data }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [selectedPopoverId, setSelectedPopoverId] = useState(null);

  const handlePopoverOpen = (event, id) => {
    setPopoverAnchor(event.currentTarget);
    setSelectedPopoverId(id);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
    setSelectedPopoverId(null);
  };

  const openPopover = Boolean(popoverAnchor);

  const handleDelete = (user) => {
    confirmAlert({
      title: "Confirm deletion",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            // Perform the delete operation
            api
              .delete(`staff/${user}`)
              .then((response) => {
                toast.success(response.data);
                updateData();
              })
              .catch((error) => {
                toast.error(error.response.status);
                if (error.response.status === 403){
                  window.location.reload()
                }
              });
          },
          style: { backgroundColor: "firebrick", color: "white" },
        },
        {
          label: "Cancel",
          onClick: () => {
            // Do nothing, cancel the deletion
          },
        },
      ],
    });
  };

  return (
    <div className="flex flex-wrap justify-center items-center gap-4 overflow-auto">
      {data.map((obj) => (
        <div className="shadow-md rounded-md mb-1 mt-1 w-[8rem]" style={{backgroundColor:colors.card2['bg']}} key={obj.id}>
          <div className="p-2">
            <div className="flex flex-col gap-1 items-center">
              <div className="flex flex-col gap-1 items-center">
                <Avatar />
                <div className="flex flex-col">
                  <h5 className="text-lg text-center">{obj.username}</h5>
                  <p className="text-sm flex items-center text-gray-500">
                    <Phone className="w-4 h-4 mr-1" /> {obj.number}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
  <button
    className="flex items-center justify-center w-8 h-8 bg-gray-200 text-red-400 rounded-full  hover:bg-red-700 hover:text-white"
    onClick={() => handleDelete(obj.id)}
  >
    <DeleteForeverOutlined />
  </button>
  <button
  className="flex items-center justify-center w-16 h-8 bg-gray-200 rounded-full hover:bg-gray-300 text-gray-600"
  onClick={(event) => handlePopoverOpen(event, obj.id)}
>
  More
</button>
</div>

            </div>
          </div>
          <Popover
  open={openPopover && selectedPopoverId === obj.id}
  anchorEl={popoverAnchor}
  onClose={handlePopoverClose}
  anchorOrigin={{
    vertical: "bottom",
    horizontal: "center",
  }}
  transformOrigin={{
    vertical: "top",
    horizontal: "center",
  }}
  sx={{
    "& .MuiPaper-root": {
      width: 155, // Adjust the width to your desired value
      fontSize: 12, // Adjust the font size to your desired value
    },
  }}
>
  <Box >
    <List>
    {/* <ListItem button>
        <ListItemIcon sx={{minWidth:'24px'}}>
          <Info sx={{fontSize:'0.8rem'}}/>
        </ListItemIcon>
        <ListItemText >
        <Typography sx={{ fontSize: '0.8rem' }} >Asset Info</Typography>
        </ListItemText>
      </ListItem> */}

      {/* <ListItem button>  
        <ListItemIcon sx={{minWidth:'24px'}}>
          <EditAttributesOutlined sx={{fontSize:'0.8rem'}}/>
        </ListItemIcon>
        <ListItemText>
        <Typography sx={{ fontSize: '0.8rem' }} >Edit</Typography>
        </ListItemText>
      </ListItem> */}
      <EditEmployee user={obj} />
      
      {/* <ListItem button>
        <ListItemIcon sx={{minWidth:'24px'}}>
          <Lock sx={{fontSize:'0.8rem'}} />
        </ListItemIcon>
        <ListItemText >
        <Typography sx={{ fontSize: '0.8rem' }} >Change Password</Typography>
        </ListItemText>
      </ListItem> */}

      <ChangePassEmployee user={obj.id}/>
    </List>
  </Box>
</Popover>

        </div>
      ))}
    </div>
  );
}

export default StaffList;
