import axios from 'axios';


const api = axios.create({
  baseURL: process.env.REACT_APP_USER_BASEURL,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 500) {
      // Handle 500 error gracefully and show a user-friendly message.
    }
    return Promise.reject(error);
  }
);


export default api ;