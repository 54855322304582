import { Fragment } from "react";
import {
  BriefcaseIcon,
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
  CurrencyDollarIcon,
  LinkIcon,
  MapPinIcon,
  PencilIcon,
  FunnelIcon,
  CurrencyRupeeIcon,
  XMarkIcon,
  PuzzlePieceIcon
} from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import { Button, List, ListItem, ListItemIcon, ListItemText, Popover } from "@mui/material";
import { Cancel, Delete, DeleteOutline, Done, Info, InfoOutlined, LocalShipping } from "@mui/icons-material";
import { useState } from "react";
import api from "../../axios";
import CompleteDeliveryModal from "./CompleteDeliveryModal";
import ApproveOrderModal from "./ApproveModal";
// import { confirm}
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import EditOrderModal from "./EditOrderModal";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}



export default function OrderDetailsBox({orderData}) {
    const [selectedStatus, setSelectedStatus] = useState("");

  const [selectedOrder, setSelectedOrder] = useState();


 


  


    //   const handleCloseDelivery = () => {
    //     setOpenDelivery(false);
    //   };






    function formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    }

    const navigate = useNavigate()

  return (
    <div className="w-full lg:flex lg:items-center lg:justify-between pb-5">
      <div className="min-w-0 flex-1">
        <div className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Order {orderData.order_id}&nbsp;&nbsp;
          <span className="text-sm sm:text-md text-yellow-600">
            {orderData.order_status}
          </span>
          {orderData.order_status === "Completed" ? (
            <span className="text-sm ml-2 sm:text-md text-indigo-600">
              {orderData.is_billable ? "Billable" : "Non-Billable"}
            </span>
          ) : (
            ""
          )}
        </div>

        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <BriefcaseIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {orderData?.company_name}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <MapPinIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {orderData?.location?.location}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <FunnelIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {orderData.requested_quantity} Ltrs
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CalendarIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {/* January 9, 2020 */}
            {formatDate(orderData.created_at)}
          </div>

          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CurrencyRupeeIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {orderData.diesel_price - orderData.discount_price}/Ltr
          </div>
        </div>
      </div>
     
    </div>
  );
}
