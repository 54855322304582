import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import axios from "axios"; // Import axios from wherever you have it
// Import your constants
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import rmlogo from "../../assets/rmlogo.png";


function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [stage, setStage] = useState("email"); // Stages: email, otp, password
  const [isLoading, setIsLoading] = useState(false)
  const [canResend, setCanResend] = useState(false);
  const [remainingTime, setRemainingTime] = useState(20); 

  const coreBaseUrl = process.env.REACT_APP_ADMIN_BASEURL;
  const navigate = useNavigate()

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "otp") {
      setOtp(value);
    } else if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const handleRequestOTP = async (event) => {
    setIsLoading(true)
    if (event) {
      event.preventDefault();
    }


    try {
      const response = await axios.post(
        `${coreBaseUrl}reset-password-request/`,
        {
          email,
        }
      );
      setMessage(response.data.message);
      setStage("otp");
    } catch (error) {
      setMessage("Something Went Wrong Please Check the Email Address.");
    }
    setIsLoading(false);
  };

  const handleVerifyOTP = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${coreBaseUrl}reset-password-confirm/`,
        {
          email,
          otp,
        }
      );
      if (response.data.success) {
        setStage("password");
        setMessage('')
      } else {
        setMessage("OTP verification failed. Please check your OTP.");
      }
    } catch (error) {
        
      setMessage("OTP verification failed. Please check your OTP.");
    }
        setIsLoading(false);

  };

  const handleUpdatePassword = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword){
        toast.error('Password Does not Match..!')
        return
    }
        setIsLoading(true);


    try {
      const response = await axios.post(`${coreBaseUrl}update-password/`, {
        email,
        newPassword,
      });
      if (response.data.success) {
        // setMessage("Password updated successfully.");
        toast.success('Password Updated Successfuly')

        navigate('/login') 
      } else {
        
        setMessage("An error occurred while updating the password.");
      }
    } catch (error) {
        
      setMessage("An error occurred while updating the password.");
    }
    setIsLoading(false);
  };

      useEffect(() => {
        let countdownInterval;

        if (!canResend) {
          countdownInterval = setInterval(() => {
            setRemainingTime((prevTime) => {
              if (prevTime > 0) {
                return prevTime - 1;
              } else {
                clearInterval(countdownInterval);
                setCanResend(true);
                return 20; // Reset the countdown
              }
            });
          }, 1000); // Decrease remaining time every 1 second
        }

        return () => {
          clearInterval(countdownInterval); // Clear interval on component unmount
        };
      }, [canResend]);

    const handleResendOTP = () => {
        setMessage('')
      setCanResend(false); // Disable resend button
      handleRequestOTP(); // Re-send OTP
    };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            mb:4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={rmlogo} width="90" height="70"></img>
          <Typography
            fontWeight="700"
            sx={{ color: "chocolate" }}
            component="h1"
            variant="h3"
          >
            ROMULUS
          </Typography>
          <Typography
            sx={{ color: "chocolate" }}
            fontWeight="700"
            component="h1"
            variant="h3"
          >
            OIL & GAS
          </Typography>
        </Box>
        <Typography component="h1" variant="h5">
          {stage === "email"
            ? "Forgot Password"
            : stage === "otp"
            ? "Enter OTP"
            : "Update Password"}
        </Typography>
        <form
          onSubmit={
            stage === "email"
              ? handleRequestOTP
              : stage === "otp"
              ? handleVerifyOTP
              : handleUpdatePassword
          }
          style={{ width: "100%", marginTop: 1 }}
        >
          {stage === "email" && (
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={handleInputChange}
            />
          )}
          {stage === "otp" && (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="otp"
                label="OTP"
                name="otp"
                autoComplete="off"
                value={otp}
                onChange={handleInputChange}
              />
              <Button
                variant="outlined"
                color="primary"
                sx={{ mt: 1, mb: 2 }}
                onClick={handleResendOTP}
                disabled={!canResend || isLoading}
              >
                {canResend ? "Resend OTP" : `Resend OTP in ${remainingTime}s`}
              </Button>
            </>
          )}
          {stage === "password" && (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="newPassword"
                label="New Password"
                name="newPassword"
                type="password"
                autoComplete="new-password"
                value={newPassword}
                onChange={handleInputChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="confirmPassword"
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                autoComplete="off"
                value={confirmPassword}
                onChange={handleInputChange}
              />
            </>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="warning"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
          >
            {stage === "email"
              ? "Request OTP"
              : stage === "otp"
              ? "Verify OTP"
              : stage === "password"
              ? "Update Password"
              : ""}

            {isLoading ? ". PLease Wait" : ""}
          </Button>
        </form>
        <Typography component="h3" color="warning" variant="body2">
          {message}
        </Typography>
        <div
        onClick={()=>navigate('/login')}
        style={{
            cursor:'pointer'
        }}
        >

            Back to Login
        </div>
      </Box>
    </Container>
  );
}

export default ForgotPassword;
