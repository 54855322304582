import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Pagination,
  useTheme,
  useMediaQuery,
  Typography,
  Box,
  FormControl,
  Modal
} from "@mui/material";
import api from "../../axios";
import { tokens } from "../../theme";

import { AuthContext } from "../../AuthContext";


import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import {
  LocalShipping,
  FormatListBulleted,
  Description,
  Opacity,
  Download,
  DeliveryDining,
  PrecisionManufacturing,
  ViewAgendaOutlined,
} from "@mui/icons-material";
import { Popover, Checkbox, FormControlLabel, Select, MenuItem } from '@mui/material';
import { toast } from "react-toastify";
import MainContext from "../../contexts/MainContext";
import { useNavigate } from "react-router-dom";


const DashboardHistory = ({order_type}) => {
  const [orders, setOrders] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [pickupPin, setPickupPin] = useState("");
  const {subContractData,selectedSubContract } = useContext(MainContext);
  const [subContract,setSubContract] = useState('')
  const [filter, setFilter] = useState({})

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token } = useContext(AuthContext);
  const navigate = useNavigate()

  const isMobile = useMediaQuery("(max-width : 600px)");

  // useEffect(() => {
  //   fetchOrders();
  // }, [orderStatus, currentPage, order_type,selectedSubContract,filter]);

  // let dummyIcon = require("../../assets/datum.png");

  // const fetchOrders = async () => {
  //   if (selectedSubContract) {
  //     const details = {
  //       order_status: orderStatus,
  //       page: currentPage,
  //       company_id: token.company_id ? token.company_id : token.id,
  //       order_type:order_type,
  //       sub_contract:selectedSubContract.id,
  //       page: currentPage,
  //       filter,
  //     }

  //     const queryParams = {
  //       page: currentPage,
  //     };
      
  //   try {
  //     const response = await api.post(`order-history/?${new URLSearchParams(queryParams)}`, details);
  //     const { results, current_page, count } = response.data;
  //     setOrders(results);
  //     setTotalPages(parseInt(count / 30) + 1);
  //   } catch (error) {
  //     console.error("Error fetching order history:", error);
  //     if (error.response.status === 403){
  //       window.location.reload()
  //     }
  //   }
  // }
  // };

  const fetchOrders = async () => {
    api.get(`order-dist/${token.company_id ? token.company_id : token.id}`).then((response)=>{
      setOrders(response.data)
    })
  }

  useEffect(() => {
    fetchOrders()
  }, [])
  

  const handleFilterChange = (status) => {
    setOrderStatus(status);
    setCurrentPage(1);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };


  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState('dateRange');
  const [selectedMonth, setSelectedMonth] = useState('June');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = () => {
    // if (!selectedMethod) {
    //   return;
    // }


    if (selectedMethod === 'dateRange') {
      if (!fromDate || !toDate) {
        toast.error('Please select from and to date.');
        return;
      }

      if (fromDate >= toDate){
        toast.error('From date should be earlier than to date')
        return;
      }
    }

    let params = {};

    if (selectedMethod === 'dateRange') {
      params = {
        company_id:token.company_id?token.company_id:token.id,
        from_date: fromDate,
        to_date: toDate,
        filter_type:'date_range'
      };
    } else if (selectedMethod === 'monthly') {
      params = {
        company_id:token.company_id?token.company_id:token.id,
        month: selectedMonth,
        filter_type:'month'
      };
    }

    api
      .get('export-orders/', {
        params,
        responseType: 'blob'
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'orders.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.error('Error downloading orders:', error);
        if (error.response.status === 403){
          window.location.reload()
        }
      });
  };

  const handleMethodChange = (event) => {
    setSelectedMethod(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'download-popover' : undefined;


    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      // width: isMobile ? "90vw" : "50vw",
      maxHeight: "80vh",
      backgroundColor: colors.background[900],
      // border: "2px solid #000",
      boxShadow: 24,
      padding: 40,
      // overflow: "auto",
      // marginLeft:''
    };

  return (
    <div>
      

<div>
      <TableContainer
        component={Paper}
        sx={{
          mt:'10px',
          height: isMobile ? "500px" : "260px",
          marginBlock: 2,
          mt:'-15px',
          pt: 0,
          overflow: "hidden",
          bgcolor: colors.card2["bg"],
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {order_type === "client" && (
                <>
                <TableCell style={{ fontWeight: "600" }}>Asset</TableCell>
                <TableCell style={{ fontWeight: "600" }}>Taken From</TableCell>
                </>
              )}
              <TableCell style={{ fontWeight: "600" }}>Asset</TableCell>
              {/* <TableCell style={{ fontWeight: "600" }}>Order ID</TableCell> */}
              {/* <TableCell style={{ fontWeight: "600" }}>Sub Contract</TableCell> */}
              {/* <TableCell style={{ fontWeight: "600" }}>Ordered By</TableCell> */}
              <TableCell align="center" style={{ fontWeight: "600" }}>
                Time
              </TableCell>
              <TableCell align="center">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Opacity sx={{ marginRight: "5px" }} />
                  <span style={{ fontWeight: "600" }}>Quantity (Ltrs)</span>
                </Box>
              </TableCell>

              

              {/* <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Payment sx={{ marginRight: "5px" }} />
                  <span style={{ fontWeight: "600" }}>Order Total</span>
                </Box>
              </TableCell> */}

              {/* <TableCell style={{ fontWeight: "600" }} align="center">Status</TableCell> */}
             

            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => {
              let assetIcon;
              if (order.asset_type === "Datum") {
                assetIcon = require("../../assets/datum.png");
              } else if (order.asset_type === "Heavy vehicle") {
                assetIcon = require("../../assets/truck.png");
              } else if (order.asset_type === "Infra Machine") {
                assetIcon = require("../../assets/citytech-logo.png");
              } else if (order.asset_type === "DG set") {
                assetIcon = require("../../assets/generator.png");
              } else if (order.asset_type === "Industrial") {
                assetIcon = require("../../assets/factory.png");
              } else if (order.asset_type === "Others") {
                assetIcon = require("../../assets/oil-pump.png");
              } else {
                assetIcon = require("../../assets/industry.png");
              }

              return (
                <TableRow key={order.id}>
                  {/* <TableCell>
                  <Box display="flex" alignItems="center">
                    <img
                      src={assetIcon}
                      // alt={order.asset.name}
                      style={{
                        width: "22px",
                        height: "22px",
                        marginRight: "10px",
                        filter: colors.pngcol["filter"],
                      }}
                    />
                    <Box display="flex" flexDirection="column">
                      <Typography fontWeight="700">
                        {order.asset_type === 'Heavy vehicle'?order.asset_reg:order.asset_name}
                      </Typography>
                      <Typography fontSize='12px'>{order.asset_type}</Typography>
                    </Box>
                  </Box>
                </TableCell> */}
                  {order_type === "client" && (
                    <>
                    <TableCell>
                      <div style={{display:'flex',flexDirection:'row',gap:'6px'}}>
                      <img style={{height:isMobile?'20px':'40px'}} width='30' src={assetIcon} />
                      <Typography display='flex' flexDirection='column'>
                        <span style={{ fontWeight: "700", fontSize: "15px" }}>
                          {order.asset_name}
                        </span>
                        <span style={{ fontSize: "12px" }} >
                          {order.asset_type}
                        </span>
                      </Typography>
                      </div>
                    </TableCell>

                    <TableCell>
                      <Typography fontWeight="600">
                        <span style={{ fontWeight: "700", fontSize: "15px" }}>
                          {order.from_datum_name}
                        </span>
                      </Typography>
                    </TableCell>
                    </>
                  )}

                    <TableCell>
                      <div style={{display:'flex',flexDirection:'row',gap:'6px'}}>
                      <img height='20' style={{height:isMobile?'30px':'40px'}} width='30' src={assetIcon} />
                      <Typography display='flex' flexDirection='column'>
                        <span style={{ fontWeight: "700", fontSize: "15px" }}>
                          {order.asset_name}
                        </span>
                        <span style={{ fontSize: "12px" }} >
                          {order.asset_type}
                        </span>
                      </Typography>
                      </div>
                    </TableCell>

                  {/* <TableCell>
                    <Typography fontWeight="600">
                      <span style={{ fontWeight: "700", fontSize: "15px" }}>
                        {order.id}
                      </span>
                    </Typography>
                  </TableCell> */}

                 

                  {/* <TableCell>
                    <Typography fontWeight="600">
                      <span style={{ fontWeight: "700", fontSize: "15px" }}>
                        {order.ordered_by_name}
                      </span>
                    </Typography>
                  </TableCell> */}

                  <TableCell align="center">
                    <Typography fontWeight="600">
                      {formatDate(order.created_at)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      sx={{
                        // fontWeight: "700",
                        display: "flex",
                        alignItems: "center", // Align content vertically in the center
                        justifyContent: "center", // Align content horizontally in the center
                        flexDirection: "column",
                      }}
                    >
                      {/* <span>Requested :{order_type === 'romulus'? order.requested_quantity:order.quantity}(Ltrs)</span> */}
                      
                      <span style={{ fontWeight: "700" }}>
                        {" "}
                        {order.quantity
                          ? order.quantity
                          : 0}
                        (Ltrs)
                      </span>

                      {/* Your content */}
                    </Typography>
                  </TableCell>

                  {/* <TableCell>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        display: "flex",
                        alignItems: "center", // Align content vertically in the center
                        justifyContent: "center", // Align content horizontally in the center
                        flexDirection: "column",
                      }}
                    >
                      <span>Requested : ₹ {order.requested_total_price}</span>
                      <span>
                        Delivered : ₹{" "}
                        {order.delivered_cost ? order.delivered_cost : 0}
                      </span>

                    </Typography>
                  </TableCell> */}

                  {/* <TableCell align='center'>
                    <Typography
                      textAlign="center"
                      sx={{
                        fontWeight: "700",
                        // display: "flex",
                        // alignItems: "center",
                        color:
                          order.order_status === "Approved"
                            ? "orange"
                            : order.order_status === "Cancelled"
                            ? "firebrick"
                            : order.order_status === "ordered"
                            ? "green"
                            : order.order_status === "Delivered"
                            ? "#1476c2"
                            : order.order_status === "Completed"
                            ? "#1476c2"
                            : "inherit",
                      }}
                    >
                      {order.order_status}
                    </Typography>
                    
                  </TableCell> */}

                  {/* {order_type === 'client' &&<TableCell>
                  {order.order_status === 'ordered' && (
  (token.user_type === 'manager' || token.user_type === 'company' || order.assigned_staff === token.id) && (
    <Typography fontWeight="600">
      <Button
        onClick={() => {
          setOpenModal(true);
          setPickupPin(order.pickup_pin);
        }}
        variant="contained"
      >
        View Pin
      </Button>
    </Typography>
  )
)}
                  </TableCell>} */}


                 


                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      </div>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box style={style}>
          <h1 className="text-xl">Pin Details</h1>
          <p className="text-lg font-bold text-blue-500">{pickupPin}</p>
        </Box>
      </Modal>

        {/* <div className="bg-white" style={{position:'fixed',bottom:'60px'}}> */}
      
        {/* </div> */}
    </div>
  );
};

export default DashboardHistory;
