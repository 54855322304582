import { Box, Typography, useTheme, Button, Divider, CircularProgress } from "@mui/material";

import { tokens } from "../theme";

import Header from "../components/Header";

import React, { useContext, useEffect, useState } from "react";

import api from "../axios";
import MainContext from "../contexts/MainContext";

import { useMediaQuery } from "@mui/material";
import StatBox from "../components/StatBox";
import {
  LocalGasStation,
  Route,
  Co2,
  CurrencyRupee,
  Money,
  Payment,
  Sell,
  Info,
  LocalShipping,
} from "@mui/icons-material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { AuthContext } from "../AuthContext";

// import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import CountUp from "react-countup";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ZoomableBarChart from "../components/HomePage/DailyOrder3";
import PieChartComponent from "../components/HomePage/PieLocationChart";
import FuelLevelBox from "../components/HomePage/StaticTank";
import { Tank } from 'smart-webcomponents-react/tank';
import StatisticsCard from "../components/HomePage/StatisticsCard";
import {
  BanknotesIcon,
  UserPlusIcon,
  UserIcon,
  ChartBarIcon,
} from "@heroicons/react/24/solid";
// import HistoryBox from "../components/OrderHistoryPage/HistoryBox";

// import DashboardHistory from "../components/OrderHistoryPage/HistoryBoxTable";
import DashboardHistory from "../components/HomePage/OrderHistory";





const AssetPage = () => {


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [rowData, setRowData] = useState();
  const { subContractData,selectedSubContract,setSelectedSubContract } = useContext(MainContext);
  const isMobile = useMediaQuery("(max-width : 600px)");
  const { token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true)
  // const [subContractData, setSubContractData] = useState([])
  // const [subContract, setSubContract] = useState('')
  

  // const getSubContract = () => {
  // }



  const statisticsCardsData = [
    {
      ico:<FuelLevelBox currentFuelLevel={rowData?.static_tank_level} capacity={rowData?.static_tank_capacity} />,
      color: "blue",
      icon: BanknotesIcon,
      title: "Fuel Level",
      value: "900 Ltrs",
      footer: {
        color: "text-green-500",
        value: "+55%",
        label: "than last week",
      },
    },
  
    {
      ico:
      <div>
  <h1 style={{fontSize:'20px',marginTop:'-15px'}} className="font-bold text-4xl">Current Load in Bowser</h1>
      <StatBox
      title={`${rowData?.current_loaded_qty === null?0:rowData?.current_loaded_qty} Ltrs`}
      // subtitle="Monthly Purchase Qty"
      progress="0.75"
      increase="+14%"
      icon={
        <LocalShipping
        sx={{ color:'brown', fontSize: "46px" ,mt:1.5}}
        />
      }
      />
      </div>
    ,
      color: "orange",
      icon: ChartBarIcon,
      title: "Sales",
      value: "$103,430",
      footer: {
        color: "text-green-500",
        value: "+5%",
        label: "than yesterday",
      },
    },
    
    {
      ico:
      <div>
        <h1 style={{fontSize:'20px',marginTop:'-15px'}} className="font-bold text-4xl">Purchase Qty</h1>
        <LocalGasStation sx={{ color:'darkcyan', fontSize: "46px" ,mt:1}}/>
      <div className="flex flex-row justify-center ">
  
      <StatBox
      title={`${rowData?.today_purchase_qty} Ltrs`}
      subtitle="Today"
      progress="0.75"
      increase="+14%"
      // icon={
      //   <LocalGasStation
      //   sx={{ color:'darkcyan', fontSize: "46px" }}
      //   />
      // }
      />
  
  <StatBox
      title={`${rowData?.monthly_purchase_quantity} Ltrs`}
      subtitle="This Month"
      progress="0.75"
      increase="+14%"
      // icon={
      //   <LocalGasStation
      //   sx={{ color:'darkcyan', fontSize: "46px" }}
      //   />
      // }
      />
      </div>
      </div>
    
    ,
      color: "green",
      icon: UserPlusIcon,
      title: "New Clients",
      value: "3,462",
      footer: {
        color: "text-red-500",
        value: "-2%",
        label: "than yesterday",
      },
    },
    
  ];


  const getData = () => {

    api
      .get(`dashboard_data/${token.company_id ? token.company_id : token.id}`,{
        params: {
            contract_id: selectedSubContract.id
        }
    })
      .then((response) => {
        setRowData(response.data);
        
      })
      .catch((error) => {
        if (error.response.status === 403){
          window.location.reload()
        }
      });
      setIsLoading(false)
  };

  useEffect(() => {
    getData();
  }, [selectedSubContract]);


  return (
    <Box sx={{ marginInline: "20px", overflow: "auto" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Dashboard" />
        <Box display='flex' flexDirection={isMobile?'column':'row'}>
          {/* <span style={{minWidth:'120px',textAlign:'center'}}>Showing Data Of : {selectedSubContract.trade_name}</span> */}
          {/* <select
                  value={subContract}
                  onChange={(e)=>setSubContract(e.target.value)}
                  className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-indigo-500 focus:shadow-outline"
                >

                  {subContractData.map((obj) => {
                    return(

                    <option value={obj.id} selected={obj.is_default} >{obj.trade_name}</option>
                  )})}
                </select> */}

        </Box>
      </Box>

      {/* <Box maxWidth="70vw"></Box> */}

      <Box
        borderRadius="10px"
        // height={isMobile ? "500px" : "400px"}
        maxWidth={isMobile ? "auto" : "auto"}
        // marginTop="5px"
        // style={{ overflowX: "auto" }}
      >
        {/* <AssetsBox /> */}
        {/* <Typography variant="h3" color={colors.grey[500]} fontWeight="600">Overview Of this Month</Typography> */}
        <Box 
        flexDirection={isMobile?'column':'row'}
        paddingX="10px" paddingTop='5px' display="flex" marginTop="5px" gap="10px">
          {/* <Box
            height="120px"
            width={"100%"}
            backgroundColor={colors.card2["bg"]}
            borderRadius="4px"
            boxShadow="0 2px 6px rgba(0, 0, 0, 0.16)"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FuelLevelBox currentFuelLevel={900} capacity={1000} />
          

          </Box> */}

          {/* <div className="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4"> */}
        {statisticsCardsData.map(({ ico, color, icon, title, footer, ...rest }) => (
          <StatisticsCard
          color={color}
            key={title}
            {...rest}
            title={title}
            ico={ico}
            icon={React.createElement(icon, {
              className: `w-1 h-1 text-${color}-500`,
            })}
            footer={
              <Typography className="font-normal text-blue-gray-600">
                <strong className={footer.color}>{footer.value}</strong>
                &nbsp;{footer.label}
              </Typography>
            }
          />
        ))}
      {/* </div> */}

          {/* <Box
            height="120px"
            width={"100%"}
            backgroundColor={colors.card2["bg"]}
            borderRadius="4px"
            boxShadow="0 2px 6px rgba(0, 0, 0, 0.16)"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={`₹ ${
                rowData?.monthly_purchase_cost === undefined ||
                rowData?.monthly_purchase_cost === null
                  ? "0"
                  : rowData.monthly_purchase_cost
              }`}
              subtitle="Monthly Purchase Cost"
              progress="0.75"
              increase="+14%"
              icon={
                <CurrencyRupeeIcon
                  sx={{ color: colors.pure[900], fontSize: "36px" }}
                />
              }
            />
          </Box> */}

          {/* <Box
            height="120px"
            width={"100%"}
            // backgroundColor={colors.primary[400]}
            backgroundColor={colors.card2["bg"]}
            borderRadius="4px"
            boxShadow="0 2px 6px rgba(0, 0, 0, 0.16)"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={`₹ ${
                rowData?.total_outstanding === undefined ||
                rowData?.total_outstanding === null
                  ? "0"
                  : rowData?.total_outstanding
              }`}
              subtitle="Total Outstanding amount"
              progress="0.75"
              increase="+14%"
              icon={
                <CreditCardIcon
                  sx={{ color: colors.pure[900], fontSize: "40px" }}
                />
              }
            />
          </Box> */}

          {/* <Box
            height="120px"
            width={isMobile?"49%":'29%'}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="25689 Rs"
              subtitle="Money Saved"
              progress="0.75"
              increase="+14%"
              icon={
                <CurrencyRupee
                  sx={{ color: colors.pure[900], fontSize: "36px" }}
                />
              }
            />
          </Box> */}
        </Box>

        {/* <Box display="flex" marginTop="5px" gap="6px">
          <Box
            height="120px"
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="25689 Rs"
              subtitle="Money Saved"
              progress="0.75"
              increase="+14%"
              icon={
                <SavingsIcon
                  sx={{ color: colors.pure[900], fontSize: "36px" }}
                />
              }
            />
          </Box>

          <Box
            height="120px"
            width={"100%"}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="12,36 Kms"
              subtitle="Kilometers Saved"
              progress="0.75"
              increase="+14%"
              icon={
                <Route sx={{ color: colors.pure[900], fontSize: "36px" }} />
              }
            />
          </Box>

          <Box
            height="120px"
            width={"100%"}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="12 Kgs"
              subtitle="Carbon Saved"
              progress="0.75"
              increase="+14%"
              icon={<Co2 sx={{ color: colors.pure[900], fontSize: "40px" }} />}
            />
          </Box>
        </Box> */}

        {/* <Box
          height="180px"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          paddingInline="50px"
          alignItems="center"
          backgroundColor={colors.card2["bg"]}
          borderRadius="4px"
          boxShadow="0 2px 6px rgba(0, 0, 0, 0.16)"
          marginTop="10px"
          width="100%"
          sx={{
            "@media (max-width: 600px)": {
              flexDirection: "column",
              height: "auto",
              padding: "20px",
              gap: "20px",
            },
          }}
        >
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            justifyContent="center"
            alignItems="center"
            gap="10px"
          >
            <LazyLoadImage
              height={250}
              width={250}
              src={require("../img/save2.png")}
              style={{
                marginBottom: "25px",
                fontSize: isMobile ? "1.5rem" : "inherit",
              }}
              sx={{
                "@media (max-width: 600px)": {
                  height: isMobile ? "80px" : "100px",
                  width: isMobile ? "80px" : "100px",
                },
              }}
            />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="left"
              sx={{
                ml: isMobile ? 0 : 6,
                fontSize: isMobile ? "0.8rem" : "inherit",
                marginBottom: isMobile ? "10px" : "inherit",
              }}
            >
              <Typography
                fontWeight="600"
                sx={{ ml: isMobile ? 4 : 0 }}
                variant="h2"
              >
                You Have{" "}
                <span style={{ fontWeight: "600", fontSize: "35px" }}>
                  Saved
                </span>
              </Typography>
              <Typography
                fontWeight="600"
                color={colors.grey[700]}
                variant="h6"
                display="flex"
                alignItems="center"
                sx={{ fontSize: isMobile ? "11px" : "14px" }}
              >
                <Info />
                You will Save More than 1000 Rs per 1050 Ltrs with Romulus
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            gap="50px"
            marginRight="20px"
            sx={{
              "@media (max-width: 600px)": {
                flexDirection: "column",
                gap: "20px",
                alignItems: "flex-start",
                marginRight: 0,
              },
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
             
              <Typography
                fontWeight="600"
                variant="h3"
                fontSize={isMobile ? "1.5rem" : "2rem"}
              >
                {!isLoading && (
                  <CountUp
                    start={0}
                    end={
                      rowData?.monthly_saved_amt === undefined ||
                      rowData?.monthly_saved_amt === null
                        ? "0"
                        : rowData?.monthly_saved_amt
                    }
                    duration={2.75}
                    separator=" "
                    decimals={2}
                    decimal="."
                    prefix="₹ "
                
                  >
                    {isLoading && <CircularProgress />}
                  </CountUp>
                )}
              </Typography>
              <Typography fontWeight="600" variant="h5">
                This Month
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >

              <Typography
                fontWeight="600"
                variant="h3"
                fontSize={isMobile ? "1.5rem" : "2rem"}
              >
                {!isLoading && (
                  <CountUp
                    start={0}
                    end={
                      rowData?.total_saved_amt === undefined ||
                      rowData?.total_saved_amt === null
                        ? "0"
                        : rowData?.total_saved_amt
                    }
                    duration={2.75}
                    separator=" "
                    decimals={2}
                    decimal="."
                    prefix="₹ "
                  
                  ></CountUp>
                )}
              </Typography>
              <Typography fontWeight="600" variant="h5">
                In Total
              </Typography>
            </Box>
          </Box>
        </Box> */}

        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
          className="p-2 gap-1"
        >
          <Box
            width={isMobile ? "100%" : "40%"}
            // marginBottom={{ base: "1rem", sm: 0 }} 
          >
            <Box
              backgroundColor={colors.card2["bg"]}
              borderRadius="4px"
              boxShadow="0 2px 6px rgba(0, 0, 0, 0.16)"
              display="flex"
              alignItems="center"
              justifyContent="space-evenly"
              flexDirection="column"
              // padding="1rem"
              height="100%"
            >
              <span className="font-bold text-lg">Lastest Distributions</span>
              {/* <StatBox
                title={`₹ ${
                  rowData?.monthly_saved_amt === undefined ||
                  rowData?.monthly_saved_amt === null
                    ? "0"
                    : rowData?.monthly_saved_amt
                }`}
                subtitle="This Month"
                progress="0.75"
                increase="+14%"
                icon={
                  <CreditCardIcon
                    sx={{ color: colors.pure[900], fontSize: "40px" }}
                  />
                }
              /> */}

              {/* <StatBox
                title={`₹ ${
                  rowData?.total_saved_amt === undefined ||
                  rowData?.total_saved_amt === null
                    ? "0"
                    : rowData?.total_saved_amt
                }`}
                subtitle="From This August"
                progress="0.75"
                increase="+14%"
                icon={
                  <CreditCardIcon
                    sx={{ color: colors.pure[900], fontSize: "40px" }}
                  />
                }
              /> */}
              <DashboardHistory order_type='romulus' position='dashboard' />
            </Box>
          </Box>

          <Box width={isMobile ? "100%" : "60%"}>
          <div
      style={{
        margin: "auto",
        position: "relative",
        height:'320px',
        // boxShadow:
        //   "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        boxShadow:"0 2px 6px rgba(0, 0, 0, 0.16)",
        borderRadius: "4px",
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column'
      }}
    >
      <h1 className="text-4xl font-bold" style={{fontSize:'2rem',color:'green'}}>0 Issues <Info/> </h1>
      <h1 className="font-semibold" style={{fontSize:"1rem",padding:'10px'}}>You Are Going Good, No Points Of Concern At The Moment</h1>
    </div>
            {/* <ZoomableBarChart type={"daily"} chart={rowData?.daily_qty_chart} /> */}
          </Box>
        </div>

        {/* <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
          className="p-2 gap-1"
        >
          <Box
            width={isMobile ? "100%" : "40%"}
            marginBottom={{ base: "1rem", sm: 0 }} // Margin bottom only on small screens
          >
            <PieChartComponent data={rowData?.loc_qty_data} />
          </Box>

          <Box width={isMobile ? "100%" : "60%"}>
            <ZoomableBarChart type={"asset"} />
          </Box>
        </div> */}

        {/* <ZoomableBarChart /> */}
      </Box>
    </Box>
  );
};

export default AssetPage;
