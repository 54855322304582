import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import {
  AccountCircleOutlined,
  CreditCard,
  Description,
  DescriptionOutlined,
  History,
  LocalGasStation,
  LocalGasStationOutlined,
  LocalShipping,
  LocalShippingOutlined,
  Payments,
  Person2Rounded,
  PrecisionManufacturing,
  ShoppingBagOutlined,
} from "@mui/icons-material";
import { AuthContext } from "../../AuthContext";
import { useContext } from "react";
import rmlogo from '../../assets/rmlogo.png'
// import { DocumentIcon, HomeModernIcon } from "@heroicons/react/20/solid";

const Item = ({ title, to, icon, selected, setSelected, setIsHidden }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleSelect = (title) => {
    localStorage.setItem("selected", title);
    setSelected(title);
    if (window.innerWidth <= 600) {
      setIsHidden(true);
    }
  };

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => handleSelect(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const SidebarNew = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState(localStorage.getItem("selected"));
  const isMobile = useMediaQuery("(max-width : 600px)");
  const [isHidden, setIsHidden] = useState(isMobile ? true : false);
const { token } = useContext(AuthContext);
  const handleCollapse = () => {
    if (isMobile) {
      setIsHidden(true);
      setIsCollapsed(false);
    } else {
      setIsCollapsed(!isCollapsed);
    }
  };

  return (
    <>
      {isHidden === false ? (
        <Box
          sx={{
            "& .pro-sidebar-inner": {
              background: `${colors.grey[900]} !important`,
            },
            "& .pro-icon-wrapper": {
              backgroundColor: "transparent !important",
            },
            "& .pro-inner-item": {
              padding: "5px 35px 5px 20px !important",
            },
            "& .pro-inner-item:hover": {
              // color: "#868dfb !important",
              color:`#3c98d9 !important`
            },
            "& .pro-menu-item.active": {
              backgroundColor: "#0077cc !important",
              color:'white !important',
              borderRadius:'12px',
              width:'95%'
              
            },
            position: isMobile ? "absolute" : "relative",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
          }}
        >
          <ProSidebar collapsed={isCollapsed}>
            <Menu iconShape="square">
              {/* LOGO AND MENU ICON */}
              <MenuItem
                onClick={() => handleCollapse()}
                icon={isCollapsed ? <MenuOutlinedIcon  /> : undefined}
                style={{
                  margin: "10px 0 20px 0",
                  color: colors.grey[100],
                }}
              >
                {!isCollapsed && (
                  <Box display="flex" flexDirection="row">
                    <Person2Rounded sx={{ fontSize: "3.5rem" }} />
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="left"
                    >
                      <Typography
                        display="flex"
                        alignItem="center"
                        variant="h5"
                        fontWeight="600"
                        color={colors.grey[100]}
                      >
                        {/* {token.username} */}
                        Demo Mishra
                      </Typography>

                      <Typography
                        variant="p"
                        fontWeight="600"
                        fontSize="0.7rem"
                        color={colors.grey[100]}
                      >
                        Company : Jindal Steel Pvt Ltd
                        {/* {token.company_name} */}
                      </Typography>

                      <Typography
                        display="flex"
                        alignItem="center"
                        fontWeight="600"
                        fontSize="0.7rem"
                        variant="p"
                        color={colors.grey[100]}
                        marginTop="-6px"
                      >
                       
                        {token.user_type.charAt(0).toUpperCase() +
                          token.user_type.slice(1)}
                      </Typography>
                    </Box>
                  </Box>
                //   <Box
                //   display="flex"
                  
                //   borderRadius="3px"
                //   alignItems="center"
                //   marginLeft={isMobile ? '40px' : '0'}
                // >
                //   <img src={rmlogo} width={isMobile ? 30 : 48} height={isMobile ? 26 : 41} alt='logo'></img>
                //   <Typography sx={{ textAlign: "center" }}>
                //     <span style={{ display: "block", lineHeight: "1",fontSize:isMobile?'1rem':'1.5rem',color:'chocolate' }}>ROMULUS</span>
                //     <span style={{ display: "block", lineHeight: "1",fontSize:isMobile?'1rem':'1.5rem',color:'chocolate' }}>OIL &amp; GAS</span>
                //   </Typography>
                // </Box>
                )}
              </MenuItem>

              <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                {!isMobile && <Item
                  title="Home"
                  to="/"
                  icon={<HomeOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  setIsHidden={setIsHidden}
                />}

{!isMobile && <Item
                  title="Assets"
                  to="/assets"
                  icon={<PrecisionManufacturing />}
                  selected={selected}
                  setSelected={setSelected}
                  setIsHidden={setIsHidden}
                />}
                
             

                {token.user_type === "company" ||
                token.user_type === "admin" ? (
                  <Item
                    title="Employees"
                    to="/Staff"
                    icon={<ContactsOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                    setIsHidden={setIsHidden}
                  />
                ) : (
                  ""
                )}

                {!isMobile && <Item
                  title="Order"
                  to="/order"
                  icon={<LocalGasStation />}
                  selected={selected}
                  setSelected={setSelected}
                  setIsHidden={setIsHidden}
                />}

                {/* {!isMobile &&<Item
                  title="Fuel Stories"
                  to="/clienthistory"
                  icon={<History />}
                  selected={selected}
                  setSelected={setSelected}
                  setIsHidden={setIsHidden}
                />} */}

                {!isMobile &&<Item
                  title="Order History"
                  to="/orderhistory"
                  icon={<History />}
                  selected={selected}
                  setSelected={setSelected}
                  setIsHidden={setIsHidden}
                />}

                {/* <Item
                  title="Invoices"
                  to="/invoice"
                  icon={<DescriptionOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                  setIsHidden={setIsHidden}
                />

              
                {!isMobile &&<Item
                  title="Transactions"
                  to="/transactions"
                  icon={<Payments />}
                  selected={selected}
                  setSelected={setSelected}
                  setIsHidden={setIsHidden}
                />} */}

              </Box>
            </Menu>
          </ProSidebar>
        </Box>
      ) : (
        <MenuOutlinedIcon
          sx={{ position: "absolute", m: 3,mt:3.8 }}
          onClick={() => setIsHidden(!isHidden)}
        />
      )}
    </>
  );
};

export default SidebarNew;
