import React, { useState } from "react";
import { Button, Modal, FormControl, Select, MenuItem, InputLabel, Typography, useTheme, Chip, TextField } from "@mui/material";
import { Close, FilterAlt, FilterAltOutlined, Tune } from "@mui/icons-material";
import { tokens } from "../../theme";
import { useContext } from "react";
import { AuthContext } from "../../AuthContext";
import { useEffect } from "react";
import { toast } from "react-toastify";

const OrderFilterModal = ({ orderType, onApplyFilter, onClearFilters }) => {
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedAssetType, setSelectedAssetType] = useState("");
  const [open, setOpen] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false); 
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [error, setError] = useState(null)

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token } = useContext(AuthContext);

  const handleApplyFilter = () => {
    if (selectedStartDate){
        if (selectedEndDate === null){
            setError('Select End Date or Clear From Date')
            return
        } 
    }
    const filterObject = {
      ...(selectedStatus && { order_status: selectedStatus }),
      ...(selectedAssetType && { asset__typeOfAsset: selectedAssetType }),
      // Include the date range filter if selected
      ...(selectedStartDate && selectedEndDate && {
        created_at__gte: selectedStartDate,
        created_at__lte: selectedEndDate,
      }),
    };

    onApplyFilter(filterObject);
    setOpen(false);
  };
  
  const handleClearAllFilters = () => {
    setSelectedStatus(""); // Clear the selectedStatus state
    setSelectedAssetType(""); // Clear the selectedAssetType state
    // setFiltersApplied(false); // Reset filtersApplied to false
    onClearFilters()
    setOpen(false)
  };

  // Function to clear a selected filter
  const handleClearFilter = (filterName) => {
    if (filterName === "order_status") {
      setSelectedStatus("");
    } else if (filterName === "asset__typeOfAsset") {
      setSelectedAssetType("");
    }
  };

  // Function to render filter chips
  const renderFilterChips = () => {
    const chips = [];
    if (selectedStatus) {
      chips.push(
        <Chip
          key="order_status"
          label={`Status: ${selectedStatus}`}
          onDelete={() => handleClearFilter("order_status")}
          color="primary"
        />
      );
    }
    if (selectedAssetType) {
      chips.push(
        <Chip
          key="asset__typeOfAsset"
          label={`Asset Type: ${selectedAssetType}`}
          onDelete={() => handleClearFilter("asset__typeOfAsset")}
          color="primary"
        />
      );
    }
    return chips;
  };


  useEffect(() => {
    // Check if filters are applied and update filtersApplied state
    if (selectedStatus || selectedAssetType || selectedStartDate || selectedEndDate) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }
  }, [selectedStatus, selectedAssetType]);

  return (
    <>
      <Button
        sx={{
          bgcolor:filtersApplied ? 'chocolate': colors.card["bg"],
          color: filtersApplied ? 'white': colors.pure[900],
          "&:hover": {
            backgroundColor: colors.card["hover"],
          },
        }}
        onClick={() => setOpen(true)}
        startIcon={filtersApplied ? <FilterAlt /> : <FilterAltOutlined/>}
        endIcon={filtersApplied?<Tune/>:''}
      >
        {filtersApplied ? "Filter Applied | Change" : "Filter"}
      </Button>
      <Modal 
      BackdropProps={{
        // Add this prop to prevent clicking outside from closing
        onClick: (event) => {
          event.stopPropagation(); // Prevent the modal from closing
        },
      }}
      open={open} onClose={() => setOpen(false)}>
        <div style={style}>
          <h1 className="text-xl mb-4">Filter Orders</h1>
          <div className="mb-2">{renderFilterChips()}</div>
          <div>
          <FormControl fullWidth margin="normal">
            <Typography>Order Status</Typography>
            <Select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="ordered">Ordered</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
            </Select>
          </FormControl>
          {orderType === 'client' && (
            <FormControl fullWidth margin="normal">
              <Typography>Asset Type</Typography>
              <Select
                value={selectedAssetType}
                onChange={(e) => setSelectedAssetType(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Datum">Datum</MenuItem>
                <MenuItem value="Heavy vehicle">Heavy vehicle</MenuItem>
                {/* Add more asset type options as needed */}
              </Select>
            </FormControl>
          )}
          <div className="flex gap-1">


          <TextField
            fullWidth
            margin="normal"
            type="date"
            label="Start Date"
            value={selectedStartDate || ""}
            onChange={(e) => setSelectedStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            fullWidth
            margin="normal"
            type="date"
            label="End Date"
            value={selectedEndDate || ""}
            onChange={(e) => setSelectedEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          </div>
        <div className="flex justify-center align-center gap-1">
        
          <Button variant="contained" onClick={handleApplyFilter}>
            Apply Filter
          </Button>

          <Button 
          startIcon={<Close/>}
          color="error" variant="contained" onClick={handleClearAllFilters}>
            {filtersApplied ? 'Clear All' : 'Close'}
          </Button>
              </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OrderFilterModal;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  boxShadow: 24,
  padding: 40,
};
