import React, { useContext, useEffect, useState } from "react";
import api from "../../axios";
import { AuthContext } from "../../AuthContext";
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from "@mui/material";
import { DeleteForever, Edit, Opacity } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

function DeliveryBox({ distributionData,dataCallback }) {
  const [distribution, setDistribution] = useState([]);
  const { token, distributionUpdate } = useContext(AuthContext);
  const { orderId,type } = useParams();
  const assetIcons = {
    All: require("../../assets/industry.png"),
    Datum: require("../../assets/datum.png"),
    HeavyVehicle: require("../../assets/truck.png"),
    InfraMachine: require("../../assets/citytech-logo.png"),
    DgSet: require("../../assets/generator.png"),
    Industrial: require("../../assets/factory.png"),
    Others: require("../../assets/oil-pump.png"),
  };

  const isMobile = useMediaQuery("(max-width : 600px)");
  const getData = () => {
    api.get(`order-distribution/${orderId}`).then((response) => {

      setDistribution(response.data);
    });
  };

 
  
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };

  const handleDelete = (id) => {
    api.delete(`order-distribution/${id}`).then((response)=>{
      dataCallback()
    }).catch((error)=>{
    
    })
  }

  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    getData();
  }, [distributionUpdate]);

  return (
    <div>
      {/* {distributionData?.distributions?.map((obj) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingInline: isMobile ? 1 : 2,
              paddingBlock: isMobile ? 0.3 : 0.5,
              border: "1px solid darkgrey",
              m: isMobile ? 0 : 1,
              borderRadius: "6px",
            }}
          >
         

            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              gap="5px"
              justifyContent="space-between"
            >
              <Box className="w-[40px]" sx={{width:'40px'}}  display="flex" flexDirection="row" alignItems="center">
                <img
                  src={assetIcons["HeavyVehicle"]}
                  width="35"
                  height="35"
                />
                <Box display="flex" flexDirection="column" marginTop="-3px">
                  <Typography fontWeight="700">
                

                    {obj.asset_regno
                      ? obj.asset_regno
                      : obj.asset_name}
                  </Typography>

                  <Typography fontSize="13px">
                    {obj.asset_type}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mt: 1 }}>
                <Typography>Quantity</Typography>
                <Box display="flex" flexDirection="row">
                  <Typography
                    sx={{ fontWeight: "700" }}
                  >
                    {obj.quantity}&nbsp;
                  </Typography>

                  <span> Ltrs</span>
                </Box>
              </Box>

             {type === 'edit' &&  <Box sx={{ maxWidth: isMobile ? "65px" : "8%" }}>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "firebrick",
                    mb: 0.4,
                    maxWidth: isMobile ? "40px" : "70px",
                  }}
                >
                  <DeleteForever sx={{ color: "white" }}  onClick={()=>handleDelete(obj.id)} />
                </Button>

                <Button sx={{ bgcolor: "darkcyan" }}>
                  <Edit sx={{ color: "white" }} />
                </Button>
              </Box>}

            </Box>
          </Box>
        );
      })} */}












<TableContainer
        component={Paper}
        sx={{
          height: isMobile ? "500px" : "auto",
          marginBlock: 2,
          mt:'-15px',
          pt: 0,
          overflow: "hidden",
          bgcolor: colors.card2["bg"],
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
                <TableCell style={{ fontWeight: "600" }}>Asset</TableCell>
                {/* <TableCell style={{ fontWeight: "600" }}>Taken From</TableCell> */}
              {/* <TableCell style={{ fontWeight: "600" }}>Asset</TableCell> */}
              {/* <TableCell style={{ fontWeight: "600" }}>Order ID</TableCell> */}
              {/* <TableCell style={{ fontWeight: "600" }}>Sub Contract</TableCell> */}
              {/* <TableCell style={{ fontWeight: "600" }}>Ordered By</TableCell> */}
              <TableCell align="center" style={{ fontWeight: "600" }}>
                Time
              </TableCell>
              <TableCell align="center">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Opacity sx={{ marginRight: "5px" }} />
                  <span style={{ fontWeight: "600" }}>Quantity (Ltrs)</span>
                </Box>
              </TableCell>

              <TableCell align="center">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <Opacity sx={{ marginRight: "5px" }} /> */}
                  <span style={{ fontWeight: "600" }}>Options</span>
                </Box>
              </TableCell>

              

              {/* <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Payment sx={{ marginRight: "5px" }} />
                  <span style={{ fontWeight: "600" }}>Order Total</span>
                </Box>
              </TableCell> */}

              {/* <TableCell style={{ fontWeight: "600" }} align="center">Status</TableCell> */}
             

            </TableRow>
          </TableHead>
          <TableBody>
            {distributionData?.distributions?.map((order) => {
              // let assetIcon;
              // if (order.asset_type === "Datum") {
              //   assetIcon = require("../../assets/datum.png");
              // } else if (order.asset_type === "Heavy vehicle") {
              //   assetIcon = require("../../assets/truck.png");
              // } else if (order.asset_type === "Infra Machine") {
              //   assetIcon = require("../../assets/citytech-logo.png");
              // } else if (order.asset_type === "DG set") {
              //   assetIcon = require("../../assets/generator.png");
              // } else if (order.asset_type === "Industrial") {
              //   assetIcon = require("../../assets/factory.png");
              // } else if (order.asset_type === "Others") {
              //   assetIcon = require("../../assets/oil-pump.png");
              // } else {
              //   assetIcon = require("../../assets/industry.png");
              // }

              let assetIcon = assetIcons["All"];
                if (order.asset_type === "Datum") {
                  assetIcon = assetIcons["Datum"];
                } else if (order.asset_type === "Heavy vehicle") {
                  assetIcon = assetIcons["HeavyVehicle"];
                } else if (order.asset_type === "Infra machine") {
                  assetIcon = assetIcons["InfraMachine"];
                }else if (order.asset_type === "DG set") {
                  assetIcon = assetIcons["DgSet"];
                }

              return (
                <TableRow key={order.id}>
                  <TableCell>
                  <Box display="flex" alignItems="center">
                    <img
                      src={assetIcon}
                      // alt={order.asset.name}
                      style={{
                        width: "22px",
                        height: "22px",
                        marginRight: "10px",
                        filter: colors.pngcol["filter"],
                      }}
                    />
                    <Box display="flex" flexDirection="column">
                      <Typography fontWeight="700">
                        {order.asset_type === 'Heavy vehicle'?order.asset_regno:order.asset_name}
                      </Typography>
                      <Typography fontSize='12px'>{order.asset_type}</Typography>
                    </Box>
                  </Box>
                </TableCell>
                  
                    {/* <TableCell>
                      <div style={{display:'flex',flexDirection:'row',gap:'6px'}}>
                      <img
                  src={assetIcons["HeavyVehicle"]}
                  width="35"
                  height="35"
                />
                      <Typography display='flex' flexDirection='column'>
                        <span style={{ fontWeight: "700", fontSize: "15px" }}>
                          {order.asset_name}
                        </span>
                        <span style={{ fontSize: "12px" }} >
                          {order.asset_type}
                        </span>
                      </Typography>
                      </div>
                    </TableCell> */}

                   
                    {/* <TableCell>
                      <div style={{display:'flex',flexDirection:'row',gap:'6px'}}>
                      <img height='20' width='30' src={assetIcon} />
                      <Typography display='flex' flexDirection='column'>
                        <span style={{ fontWeight: "700", fontSize: "15px" }}>
                          {order.asset_name}
                        </span>
                        <span style={{ fontSize: "12px" }} >
                          {order.asset_type}
                        </span>
                      </Typography>
                      </div>
                    </TableCell> */}

                  {/* <TableCell>
                    <Typography fontWeight="600">
                      <span style={{ fontWeight: "700", fontSize: "15px" }}>
                        {order.id}
                      </span>
                    </Typography>
                  </TableCell> */}

                 

                  {/* <TableCell>
                    <Typography fontWeight="600">
                      <span style={{ fontWeight: "700", fontSize: "15px" }}>
                        {order.ordered_by_name}
                      </span>
                    </Typography>
                  </TableCell> */}

                  <TableCell align="center">
                    <Typography fontWeight="600">
                      {formatDate(order.created_at)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      sx={{
                        // fontWeight: "700",
                        display: "flex",
                        alignItems: "center", // Align content vertically in the center
                        justifyContent: "center", // Align content horizontally in the center
                        flexDirection: "column",
                      }}
                    >
                      {/* <span>Requested :{order_type === 'romulus'? order.requested_quantity:order.quantity}(Ltrs)</span> */}
                      
                      <span style={{ fontWeight: "700" }}>
                        {" "}
                        {order.quantity
                          ? order.quantity
                          : 0}
                        (Ltrs)
                      </span>

                      {/* Your content */}
                    </Typography>
                  </TableCell>

                  {/* <TableCell>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        display: "flex",
                        alignItems: "center", // Align content vertically in the center
                        justifyContent: "center", // Align content horizontally in the center
                        flexDirection: "column",
                      }}
                    >
                      <span>Requested : ₹ {order.requested_total_price}</span>
                      <span>
                        Delivered : ₹{" "}
                        {order.delivered_cost ? order.delivered_cost : 0}
                      </span>

                    </Typography>
                  </TableCell> */}

                  {/* <TableCell align='center'>
                    <Typography
                      textAlign="center"
                      sx={{
                        fontWeight: "700",
                        // display: "flex",
                        // alignItems: "center",
                        color:
                          order.order_status === "Approved"
                            ? "orange"
                            : order.order_status === "Cancelled"
                            ? "firebrick"
                            : order.order_status === "ordered"
                            ? "green"
                            : order.order_status === "Delivered"
                            ? "#1476c2"
                            : order.order_status === "Completed"
                            ? "#1476c2"
                            : "inherit",
                      }}
                    >
                      {order.order_status}
                    </Typography>
                    
                  </TableCell> */}

                  {/* {order_type === 'client' &&<TableCell>
                  {order.order_status === 'ordered' && (
  (token.user_type === 'manager' || token.user_type === 'company' || order.assigned_staff === token.id) && (
    <Typography fontWeight="600">
      <Button
        onClick={() => {
          setOpenModal(true);
          setPickupPin(order.pickup_pin);
        }}
        variant="contained"
      >
        View Pin
      </Button>
    </Typography>
  )
)}
                  </TableCell>} */}



<TableCell align="center">
{type === 'edit' &&  <Box sx={{ maxWidth: isMobile ? "65px" : "8%",display:'flex', flexDirection:isMobile ? 'column':'row' }}>
                <Button
                onClick={()=>handleDelete(order.id)} 
                  variant="contained"
                  sx={{
                    bgcolor: "firebrick",
                    mb: 0.4,
                    maxWidth: isMobile ? "40px" : "70px",
                  }}
                >
                  <DeleteForever sx={{ color: "white" }}  />
                </Button>

                {/* <Button sx={{ bgcolor: "darkcyan" }}>
                  <Edit sx={{ color: "white" }} />
                </Button> */}
              </Box>}
                  </TableCell>
                 


                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default DeliveryBox;
