import {
    Box,
    Typography,
    useTheme,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    useMediaQuery,
  } from "@mui/material";
  import { DataGrid } from "@mui/x-data-grid";
  import { tokens } from "../theme";

  import { useEffect, useState } from "react";
  import { getRoles } from "@testing-library/react";
  import api from "../axios";
  import { useContext } from "react";
  import { AuthContext } from "../AuthContext";
  import DeliveryBox from "../components/DistributionPage/DeliveryBox";
  import { AddCircle } from "@mui/icons-material";
  import FinishDeliveryModal from "../components/DistributionPage/FinishDeliveryModal";
  import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
  
  const Delivery = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { orderId, type } = useParams();
    const [distribution, setDistribution] = useState({});
    const [filteredAsset,setFilteredAsset] = useState([])

    
    // const location = useLocation();
    // const queryParams = new URLSearchParams(location.search);
    // const type = queryParams.get('type');  
  
    
  
    const [rowData, setRowData] = useState([]);
    const assetIcons = {
      All: require("../assets/industry.png"),
      Datum: require("../assets/datum.png"),
      HeavyVehicle: require("../assets/truck.png"),
      InfraMachine: require("../assets/citytech-logo.png"),
      DgSet: require("../assets/generator.png"),
      Industrial: require("../assets/factory.png"),
      Others: require("../assets/oil-pump.png"),
    };
  
    const [asset, setAsset] = useState();
  
    const { token } = useContext(AuthContext);
    const company_id = token.company_id?token.company_id:token.id
    const getRows = async () => {
      await api
        .get(`assets/${company_id}`)
        .then((response) => {
          setRowData(response.data);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            window.location.reload();
          }
        });
    };

    const getDistributions = () => {
      
        api.get(`order-distribution/${orderId}`).then((response) => {
          setDistribution(response.data);
          
        });
      };


  
const handleSubmit = () => {
  if (asset === null){
    toast.error('Try again')
  
    window.location.reload()
  }
  if (distribution.left_in_order <= 0 || distribution.left_in_order < quantity) {
    // Display a confirmation alert
    confirmAlert({
      title: <span style={{ color: '#FF5733',fontWeight:'600' }}>Entered Quantity Exceeds the Quantity Left !</span>,
      message: ' Are you sure you want to proceed ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            // User confirmed, proceed with the order
            const details = {
              asset,
              order: orderId,
              quantity,
            };
            api
              .post("order-distribution", details)
              .then((response) => {
                setAsset(null)
                getDistributions()
              })
              .catch((error) => {
                toast.error('Something Went Wrong..! Please Try Again')
              });
          },
        },
        {
          label: 'No',
          onClick: () => {
            // User canceled, do nothing
          },
        },
      ],
    });
  } else {
    // Proceed with the order directly
    const details = {
      asset,
      order: orderId,
      quantity,
    };
    api
      .post("order-distribution", details)
      .then((response) => {
        
        getDistributions()
        setAsset(null)
      })
      .catch((error) => {
        toast.error('Something Went Wrong..! Please Try Again')
      });
  }
};
  
    const handleFinish = () => {
      if (distribution.left_in_order > 0){
        toast.error('Distribute entire diesel to finish delivery')
      }else{
        setFinishOpen(true)
      }
    }
  
    useEffect(() => {
      getRows();
      getDistributions();
    }, []);

    useEffect(() => {
      // When both rowData and distribution states are available, filter the assets.
      if (rowData.length > 0 && Object.keys(distribution).length > 0) {
        const assetsInDistribution = distribution?.distributions?.map(dist => dist.asset);
    
        const assetsNotInDistribution = rowData.filter(row => {
          return !assetsInDistribution.includes(row.id);
        });
  
        setFilteredAsset(assetsNotInDistribution);
        // setAsset(assetsNotInDistribution !== undefined? assetsNotInDistribution[1]?.id :'')
        
        // window.location.reload()
      }
      
    }, [distribution]);


    // useEffect(() => {
    //   if (distribution){
    //     setFilteredAsset(rowData.filter(obj => !distribution.some(dist => dist.id === obj.id)) )
    //   }
      
    // }, [rowData, distribution]);
  
    const [quantity, setQuantity] = useState();
    const isMobile = useMediaQuery("(max-width : 600px)");
    const [finishOpen, setFinishOpen] = useState(false)
    return (
      <Box sx={{ marginInline: "20px" }}>
        <h1 style={{fontSize:'1.5rem'}} className="font-semibold">Order Disrtibution</h1>
        {/* <Typography>Delivery Open</Typography> */}

        {type === 'edit' &&
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            mt:1,
            bgcolor: "white",
            padding: 1,
            borderRadius: "14px",
            minHeight: "100px",
            gap: 2,
            boxShadow: "0 0 8px rgba(0, 0, 0, 0.3)",
            // background: "linear-gradient(45deg, #393939, #3b3b3b);",
          }}
        >
          
          <FormControl
            fullWidth
            // margin="normal"
            sx={{
              bgcolor: "white",
              borderRadius: "8px",
              "& .MuiInputBase-root": {
                marginBottom: "0", // Set the marginBottom to 0 for the MuiInputBase-root class
              },
            }}
          >
            <InputLabel id="asset-state-label">Select an asset</InputLabel>
            <Select
              sx={{ mb: 1 }}
              name="asset_incharge"
              value={asset}
              onChange={(e) => setAsset(e.target.value)}
              variant="filled"
            >
              <MenuItem value={null}>Select</MenuItem>
              
              {filteredAsset.map((obj) => {
                let assetIcon = assetIcons["All"];
                if (obj.typeOfAsset === "Datum") {
                  assetIcon = assetIcons["Datum"];
                } else if (obj.typeOfAsset === "Heavy vehicle") {
                  assetIcon = assetIcons["HeavyVehicle"];
                } else if (obj.typeOfAsset === "Infra machine") {
                  assetIcon = assetIcons["InfraMachine"];
                }
                return (
                  <MenuItem value={obj.id}>
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="row"
                      gap="5px"
                      justifyContent="space-between"
                    >
                      <Box display="flex" flexDirection="row" gap="6px">
                        <img
                          src={assetIcon}
                          style={{ filter: colors.pngcol["filter"] }}
                          width="35"
                          height="35"
                        />
                        <Box
                          display="flex"
                          flexDirection="column"
                          marginTop="-3px"
                        >
                          <Typography fontWeight="700">
                            {obj.typeOfAsset === "Heavy vehicle"
                              ? obj.assetRegistrationNumber
                              : obj.assetName}
                          </Typography>
  
                          <Typography fontSize="13px">
                            {obj.typeOfAsset}
                          </Typography>
                        </Box>
                      </Box>
  
                      {/* <Box marginTop="-3px" width="25%" paddingLeft="5px">
                        <Typography>
                          {obj.assetRegistrationNumber !== ""
                            ? `Reg No : ${obj.assetRegistrationNumber}`
                            : `Capacity : ${obj.assetCapacity} Ltrs`}
                        </Typography>
                      </Box> */}
                    </Box>
                  </MenuItem>
                );
              })}
              
            </Select>
          </FormControl>
  
          <TextField
            sx={{ bgcolor: "white", borderRadius: "8px" }}
            label="Quantity"
            value={quantity}
            onChange={(e) => {
              setQuantity(e.target.value);
            }}
            fullWidth
          />
  
          <Button
            sx={{
              minWidth: "120px",
              bgcolor: "green",
              height: "50px",
              // color: "black",
              fontWeight: "700",
              fontSize: "1rem",
            }}
            variant="contained"
            onClick={handleSubmit}
          >
            <AddCircle /> Add
          </Button>
  
          {/* <Header title="Assets" subtitle="Managing Romulus Assets" /> */}
  
          <Box>{/* <AddAssetModal updateTable={getRows} /> */}</Box>
        </Box>

        }
  
        <Box>
          <Box
            sx={{
              // border: "1px solid darkgrey",
              boxShadow: "0 0 8px rgba(0, 0, 0, 0.3)",
              // maxHeight: '',
              mt: 1,
              height: isMobile ? "40vh" : "50vh",
              borderRadius: "10px",
              overflowX: "auto",
            }}
          >
            <DeliveryBox  distributionData={distribution} dataCallback={getDistributions} />
          </Box>
  
          {
              finishOpen?<FinishDeliveryModal order={orderId} onClose={()=>setFinishOpen(false)} />:''
            }

          <Box
            // display="flex"
            // justifyContent="center"
            // alignItems="center"
            marginTop="10px"
            height='100%'
            padding='15px'
            width='100%'
            backgroundColor='white'
            borderRadius='10px'
            // border: "1px solid darkgrey",
            boxShadow="0 0 8px rgba(0, 0, 0, 0.3)"

          >
            
            <div className={`flex ${isMobile?'flex-col':'flex-row'} align-center`} style={{justifyContent:'space-evenly'}}>

            <div className="flex flex-col align-center justify-center">
            <span className="font-semibold text-lg">Total Quantity Released : {distribution.total_distributed} (Ltrs)</span>
            <span className="font-semibold text-lg">Total Quantity Left :{distribution.left_in_order} (Ltrs)</span>
              </div>

            <div className="flex flex-col align-center justify-center">
            <span className={`font-semibold text-lg bg-${distribution.count === '0'?'red':'green'}-500`} > No of Distribution : {distribution.count}</span>
            
              </div>

              

              

            {type === 'edit' && 

              <Button variant="contained" sx={{height:'40px',alignSelf:'center'}} disabled={distribution.left_in_order > 0} onClick={handleFinish}>{distribution.left_in_order > 0 ?'Dispense Complete Fuel to Finish delivery':'Finish Delivery'}</Button>           
            }
            
            </div>
          </Box>
        </Box>
      </Box>
    );
  };
  
  export default Delivery;
  