import { LocalShipping } from "@mui/icons-material";
import React from "react";

function StatisticsCard({ color, ico, icon, title, value, footer }) {
  return (
    <div
        style={{height:'200px',paddingTop:'45px'}}
     className={`bg-${color}-200 rounded-lg p-4 pt-8 w-full shadow-md`}>
      <div className="text-center">
       
      {/* <span className="text-md">{icon}</span> */}
      {/* <LocalShipping size="5rem" sx={{color:color,fontSize:'4rem'}}/> */}
      {ico}
      </div>
      <div className="mt-4 text-center">
        {/* <p className="text-sm text-gray-600">{title}</p> */}
        {/* <p className="text-2xl text-gray-900">{value}</p> */}
      </div>
      {/* {footer && (
        <div className="mt-4 border-t border-gray-300 p-2 text-center">
          {footer}
        </div>
      )} */}
    </div>
  );
}

export default StatisticsCard;
