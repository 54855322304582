import React from 'react';
import './WaterTank.css'; // Import the CSS file
import bubbles from '../../assets/bubbles.gif'
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';

const FuelLevelBox = ({ currentFuelLevel, capacity }) => {
  // Calculate the water level percentage based on the current fuel level
  const waterLevelPercentage = (currentFuelLevel / capacity) * 100;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div className="flex flex-row align-center justify-center" style={{marginTop:"-20px"}}>

       <div className="tank">
    <div className="water" style={{ height: `${waterLevelPercentage}%` }}>
    <img src={bubbles} alt="Water Bubbles" />
      <div className="wave"></div>
    </div>
    <div className="water-bubbles text-xl">
      {/* Include the GIF here */}
      
    </div>


  </div>

      <div className='m-auto' style={{margin:'auto'}}>

        <p className='font-bold text-xl'>Static Tank</p>

        <p style={{fontSize:'1rem',color:colors.pure[900]}} className="level-text">{currentFuelLevel} / {capacity} Ltrs</p>
      </div>
        
 


  
</div>
  );
};

export default FuelLevelBox;
