import { Avatar, Box, Button, CircularProgress, IconButton, List, ListItem, ListItemText, Popover, Typography, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import rmlogo from '../../assets/rmlogo.png'
import nozzle from '../../assets/nozzle2.png'
import subcontract from '../../assets/helmet.jpg'
import api from "../../axios";
import { coreBaseUrl } from "../../constants";
import axios from "axios";
// import LoadingIndicator from "../../components/LoadingIndicator";
import ProgressCircle from "../../components/ProgressCircle";
import DarkModeBt from './DarkModeSwitch';
import { AuthContext } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import GavelIcon from '@mui/icons-material/Gavel';
import { KeyboardArrowDown, Person2Rounded, Person3Rounded } from "@mui/icons-material";
import MainContext from "../../contexts/MainContext";
import Header from "../../components/Header";

// import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const isMobile = window.innerWidth <= 600
  const { token } = useContext(AuthContext);
  
  const [anchorEl, setAnchorEl] = useState('')

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'profile-popover' : undefined;

  const [isLoading, setIsLoading] = useState(false)
  const {checkAuth} = useContext(AuthContext)
  const navigate = useNavigate()

  const coreBaseUrl = process.env.REACT_APP_ADMIN_BASEURL;
  const handleLogout = async () => {
    await axios.get(`${coreBaseUrl}logout`, {withCredentials:true}).then((response)=>{
      navigate('/login')
      // setIsLoading(true)
      // checkAuth()
      window.location.reload()
      
      
    }).catch((error)=>{
    })
    // window.location.reload()
    
    // setTimeout(() => {
    //   window.location.reload();
    //   setIsLoading(false)
    // }, 2000);
    
  }

  // if (isLoading === true ){
  //   return <LoadingIndicator/>
  // }


  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
  ];

  const selected = false
  const buttonClasses = selected
  ? 'bg-blue-500 text-white hover:bg-blue-600'
  : 'bg-gray-200 text-gray-600 hover:bg-gray-300';

  const [isOpen, setIsOpen] = useState(false);


  const handleOptionClick = (option) => {
    setSelectedSubContract(option);
    setIsOpen(false);
  };

  const { subContractData,selectedSubContract,setSelectedSubContract } = useContext(MainContext);


  return (
    <Box display="flex" justifyContent="space-between" p={2}>
    
    <img src={rmlogo} style={{height:'40px',marginLeft:'35px',marginTop:'5px'}} height={4} width={48}/>
    
    <span 
    // style={{fontSize:'1.5rem',color:'blue'}} 
    style = {{
      fontSize: '1.3rem',
      marginTop: '7px',
      // fontFamily: 'fantasy',
      // fontStyle: 'italic',
      color:'white',
      letterSpacing: '1px',
      WebkitTextStroke: '0.7px rgb(2, 3, 6)',
      textStroke: '0.5px black',
     lineHeight:'20px'



    }}
    
    className="font-semibold"></span>
      {/* <Box
        display="flex"
        borderRadius="3px"
        alignItems="center"
        marginLeft={isMobile ? '40px' : '0'}
        flexDirection='column'
      >
       
        <div
        style = {{
          fontSize: '2.5rem',
          // marginTop: '-22px',
          fontFamily: 'fantasy',
          fontStyle: 'italic',
          color:'white',
          letterSpacing: '2px',
          WebkitTextStroke: '0.5px rgb(2, 3, 6)',
          textStroke: '0.5px black',
         lineHeight:'20px'


        }}
        
        className="font-semibold flex flex-row align-center justify-center">
      
          
          <span>FMS</span>
          
          </div>
        <div className="font-bold flex flex-row relative mt-5" style={{marginTop:'8px'}}>By <img src={rmlogo} width={isMobile ? 30 : 20} height={isMobile ? 26 : 2} alt='logo'></img> <span style={{color:'chocolate'}}>Romulus</span></div>
      </Box> */}


      {/* ICONS */}
      <Box display="flex">
        <div onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            // <DarkModeOutlinedIcon />
            <DarkModeBt size='small' sx={{ m: 1 }} />
          ) : (
            // <LightModeOutlinedIcon />
            <DarkModeBt size='small' sx={{ m: 1 }} />
          )}
        </div>
        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton> */}
        
        {/* <IconButton>
          <SettingsOutlinedIcon />
        </IconButton> */}

{/* <button style={{paddingRight:'10px',height:'30px',marginTop:'10px',paddingLeft:'4px'}} className={`flex items-center px-1 rounded-full ${buttonClasses}`}>
      <img style={{mixBlendMode:'multiply',paddingRight:'3px'}} src={subcontract} alt='sub contract' width='25px' height='25px'  />
      royal
      <KeyboardArrowDown/>
    </button> */}


<div className="relative inline-block">
      {/* <button
        style={{
          paddingRight: '10px',
          height: '30px',
          marginTop: '10px',
          paddingLeft: '4px',
          
        }}
        className={`flex items-center px-1 rounded-full ${buttonClasses}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          style={{ mixBlendMode: 'multiply', paddingRight: '3px' }}
          src={subcontract}
          alt="sub contract"
          width="25px"
          height="25px"
        />
        { selectedSubContract ? selectedSubContract.trade_name : 'Select a sub contract'}
        <span
          style={{
            marginLeft: '5px',
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.2s ease',
          }}
        >
          
          <KeyboardArrowDown/>
        </span>
      </button> */}
      {isOpen && (
        <div
          className="absolute mt-1 w-32 border rounded-lg shadow-lg"
          style={{ top: '100%',zIndex:1,background:colors.card['bg'] }}
        >
          <div
              className="py-2 px-4 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleOptionClick({id:'all',trade_name : 'All'})}
            >
              All
            </div>

          {subContractData.map((option) => (
            <div
              key={option.id}
              className="py-2 px-4 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleOptionClick(option)}
            >
              {option.trade_name}
            </div>
          ))}
        </div>
      )}
    </div>


        <IconButton 
        // className="ml-2"
        onClick={handleClick}>
          <PersonOutlinedIcon />
          {/* <Box
          sx={{background:colors.card['bg'],p:0.7,pb:0,borderRadius:'25px',border:'1px solid',borderColor:'darkgrey'}}
          display="flex" flexDirection="row">
                    <Avatar sx={{bgcolor:colors.blueAccent[100],mb:0.5,mr:0.5}} />
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="left"
                      marginTop='-10px'
                      
                    >
                      <Typography
                        display="flex"
                        alignItem="center"
                        variant="h6"
                        fontWeight="600"
                        color={colors.grey[100]}
                        marginBottom='-5px'
                        marginTop='1px'
                      >
                        {token.username}
                      </Typography>

                      <Typography
                        variant="p"
                        fontWeight="600"
                        fontSize="0.7rem"
                        color={colors.grey[100]}
                      >
                        Company : {token.company_name}
                      </Typography>

                      <Typography
                        display="flex"
                        alignItem="center"
                        fontWeight="600"
                        fontSize="0.7rem"
                        variant="p"
                        color={colors.grey[100]}
                        marginTop="-6px"
                      >
                        
                        {token.user_type.charAt(0).toUpperCase() +
                          token.user_type.slice(1)}
                      </Typography>
                    </Box>
                  </Box> */}
        </IconButton>

        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          <ListItem button>
            <ListItemText primary="Profile" />
          </ListItem>
          
          {isLoading === false ?
            <ListItem button onClick={handleLogout}>
            <ListItemText primary="Logout" />
          </ListItem>
          :
          <ListItem button onClick={handleLogout}>
            <ListItemText sx={{display:'flex',flexDirection:'row', fontSize:'1rem'}} primary="Loging Out" secondary={<CircularProgress sx={{maxWidth:'10px',maxHeight:'10px'}}/>}/>
          </ListItem>
          // <CircularProgress sx={{maxWidth:'10px',maxHeight:'10px'}}/>
          }

        </List>
      </Popover>

      </Box>
    </Box>
  );
};

export default Topbar;
