import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import StaffList from "./StaffList";
import { tokens } from "../../theme";

function StaffBoxNew({ updateData, staffData, managerData }) {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  return (
    <div className="flex h-full" >
      <div className={`flex ${isMobile ? "flex-col" : "flex-row p-4 "} gap-${isMobile ? "4" : "10"}`} style={{ width: "100%" }}>
        <div style={{backgroundColor:colors.card2['bg']}} className={`p-4 ${isMobile ? "w-full h-[45%] mb-2" : "w-1/2 h-full mr-3"} rounded-md bg-white shadow-md overflow-auto`}>
          <h4 className="text-lg text-center font-bold mb-2 bg-red">Managers</h4>
          <hr className="border-gray-300 mb-2" />
          <StaffList updateData={updateData} data={managerData} />
        </div>

        <div style={{backgroundColor:colors.card2['bg']}}  className={`p-4 ${isMobile ? "w-full h-[45%]": "w-1/2 h-full"} rounded-md bg-white shadow-md overflow-auto`}>
          <h4 className="text-lg text-center font-bold mb-2">Staff</h4>
          <hr className="border-gray-300 mb-2" />
          <StaffList updateData={updateData} data={staffData} />
        </div>
      </div>
    </div>
  );
}

export default StaffBoxNew;
