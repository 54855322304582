import { useRef } from "react";
import { Routes, Route } from "react-router-dom";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainContainer from "./MainContainer";
// import UserContainer from "./UserContainer";
import LoginPage2 from "./Pages/Auth/LoginPage";
import PrivateRoute from "./PrivateRouter";
import { AuthProvider } from "./AuthContext";
import ServerDown from "./ServerDown";
import { MainContextProvider } from "./contexts/MainContext";
import ForgotPassword from "./Pages/Auth/ForgotPassword";

function App() {
  const [theme, colorMode] = useMode();

    const wrapperRef = useRef(null); // Ref for the wrapper div

      const handleWrapperClick = () => {
      toast.dismiss(); // Close the toast on wrapper click
    };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <AuthProvider>
        <MainContextProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="app" ref={wrapperRef} onClick={handleWrapperClick} >
              <Routes>
                {/* <Route path="/login" element={<UserLogin />} /> */}
                <Route path="/login" element={<LoginPage2 />} />
                <Route path="/forgotpass" element={<ForgotPassword />} />
                <Route path="/serverdown" element={<ServerDown />} />
                {/* <Route path="/test" element={<><AssetPage /><BottomNavigation/></>} /> */}
                {/* <Route path="/" element={<PrivateRoute path="/" component={UserContainer} />} /> */}
                <Route
                  path="/*"
                  element={<PrivateRoute path="/*" component={MainContainer} />}
                />
              </Routes>

            </div>
            {/* <ToastContainer
              style={{
                width: "auto",
                minWidth: "250px",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
              }}
            /> */}
            <ToastContainer
              style={{
                minWidth: "250px",
                position: "fixed",
                bottom: "20px", // Adjust the positioning as needed
                left: "20px", // Adjust the positioning as needed
                zIndex: 9999, // Higher z-index to make sure it's on top
              }}
            />
          </ThemeProvider>
        </MainContextProvider>
      </AuthProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
