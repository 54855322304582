import { Typography, Box, useTheme, Button } from "@mui/material";
import { tokens } from "../theme";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box mb="10px" sx={{display:'flex',justifyContent:"space-between"}}>
      <Box>
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{
          m: "0 0 5px 0",
          fontSize: "32px",
          "@media (max-width: 600px)": {
            fontSize: "24px",
          },
        }}
      >
        {title}
      </Typography>
      
      <Typography
        variant="h5"
        color={colors.greenAccent[400]}
        sx={{
          fontSize: "16px",
          "@media (max-width: 600px)": {
            fontSize: "14px",
          },
        }}
      >
        {subtitle}
      </Typography>

        </Box>
     
    </Box>
  );
};

export default Header;
