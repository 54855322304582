import React from 'react'
import Topbar from './scenes/global/Topbar'
import AssetPage from "./Pages/AssetPage";

import { Routes, Route } from "react-router-dom";
import { useState } from 'react';
import BottomNavbar from './scenes/global/BottomNavbar'
import { useMediaQuery, useTheme } from '@mui/material';
import HomePage from './Pages/HomePage'
import StaffPage from './Pages/StaffPage'
import OrderPage from './Pages/OrderPage'
import OrderHistoryPage from './Pages/OrderHistoryPage';
import TransactionPage from './Pages/TransactionPage';
import InvoicePage from './Pages/InvoicePage';
import SidebarNew from './scenes/global/SidebarNew';
import { tokens } from './theme';
import OrderDetails from './Pages/OrderDetails';
import Delivery from './Pages/DistributionPage';

function MainContainer() {
    const [isSidebar, setIsSidebar] = useState(true);
    const isMobile = useMediaQuery('(max-width: 1024px)');
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    return (
      <>
        {/* {!isMobile ? <Sidebar isSidebar={isSidebar} /> : ""} */}
        <SidebarNew isSidebar={isSidebar}/>
        <main className="content" style={{background:colors.back['main']}}>
          <Topbar setIsSidebar={setIsSidebar} />
          <div style={{ paddingBottom: isMobile ? "60px" : "" , height:'85%',overflow:'auto'}}>
            <Routes>
              {/* <Route path="/" element={<Dashboard />} /> */}
              <Route path="/" element={<HomePage />} />
              <Route path="/assets" element={<AssetPage />} />
              {/* <Route path="/contacts" element={<Contacts />} /> */}
              <Route path="/staff" element={<StaffPage />} />
              <Route path="/order" element={<OrderPage />} />
              <Route path="order-details/:orderId" element={<OrderDetails />} />
              <Route path="order-distribution/:orderId/:type" element={<Delivery />} />
              <Route
                path="/clienthistory"
                element={<OrderHistoryPage order_type="client" />}
              />
              <Route
                path="/orderhistory"
                element={<OrderHistoryPage order_type="romulus" />}
              />
              <Route
                path="/invoice"
                element={<InvoicePage/>}
              />
              <Route path="/transactions" element={<TransactionPage />} />
            </Routes>
          </div>
          {isMobile ? <BottomNavbar /> : ""}
        </main>
      </>
    );
}

export default MainContainer