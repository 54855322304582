import { PencilIcon, UserCircleIcon } from "@heroicons/react/20/solid";
import {
  AccountCircle,
  DeleteForeverOutlined,
  EditLocation,
  LocalGasStation,
  LocalShipping,
  RemoveCircleOutline,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import { Badge, Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import api from "../../axios";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";


export default function DeliveryDetailsBox({ orderData }) {
const [openDialog, setOpenDialog] = useState(false);





// function formatDate(dateString) {
//   const options = { year: "numeric", month: "long", day: "numeric" };
//   return new Date(dateString).toLocaleDateString(undefined, options);
// }

function formatDate(dateString) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return new Date(dateString).toLocaleString(undefined, options);
}


  return (
    <div className="bg-white">
      <div className="flex flex-row justify-between">
        <h2 className="p-3 text-xl font-bold tracking-tight text-gray-900">
          Deliveries
          <span className="inline-flex text-white items-center justify-center ml-2 text-sm bg-blue-500 rounded-full w-6 h-6">
            {orderData?.deliveries?.length}
          </span>
        </h2>

        <div className="flex flex-row">
          <p className="p-3 text-sm mt-1 font-semibold tracking-tight text-gray-900">
            Approved:{orderData?.approved_quantity} (Ltrs)
          </p>

          <p className="p-3 text-sm mt-1 font-semibold tracking-tight text-gray-900">
            Delivered:{orderData?.delivered_quantity} (Ltrs)
          </p>
        </div>
      </div>

      <div
        style={{
          overflow: "auto",
          maxHeight: "360px",
        }}
        className="mx-auto max-w-7xl px-6 lg:px-8"
      >
        {/* <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            From the blog
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Learn how to grow your business with our expert advice.
          </p>
        </div> */}
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10  sm:pt-4 lg:mx-0 lg:max-w-none lg:grid-cols-1">
          {orderData?.deliveries?.map((delivery) => (
            <article
              key={delivery.id}
              className="flex max-w-xl flex-col items-start justify-between border-b border-gray-200"
            >
              <div className="flex items-center gap-x-4 text-xs">
                <time className="text-gray-500">
                  {formatDate(delivery.created_at)}
                </time>
                <p
                  className={`relative z-10 rounded-full ${
                    delivery.status === "ongoing"
                      ? "bg-green-500"
                      : delivery.status === "completed"
                      ? "bg-blue-900"
                      : "bg-yellow-700"
                  } px-3 py-1.5 font-medium text-white hover:bg-gray-100`}
                >
                  {/* {post.category.title} */}
                  {delivery.status}
                </p>
              </div>
              <div className="group relative ">
                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                  {/* <span className="absolute inset-0" /> */}
                  Order Distributions
                </h3>
                <div className="">
                  {delivery.distribution.map((distribution) => {
                    return (
                      <div
                        key={distribution.id}
                        className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600 "
                      >
                        <LocalGasStation />
                        {distribution.asset_name}&nbsp;&nbsp;&nbsp;
                        <TextField
                          sx={{
                            maxWidth: "40px",
                            mr: 1,
                            // mt: 1,
                            "& input": {
                              padding: "1px",
                            },
                          }}
                          disabled
                          value={distribution.quantity}
                        />
                        Ltrs&nbsp;&nbsp;&nbsp;
                        {/* <button
                          type="button"
                          className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        > */}
                        {/* <RemoveCircleOutline
                          onClick={() =>
                            handleRemoveDistribution(distribution.id)
                          }
                          sx={{ color: "firebrick", cursor: "pointer" }}
                        /> */}
                        {/* </button> */}
                        &nbsp;&nbsp;&nbsp;
                        {/* <button
                      type="button"
                      className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                     <DeleteForeverOutlined />
                    </button> */}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="relative mt-8 flex items-center gap-x-4">
                

               

              
                <LocalShipping sx={{ fontSize: "2.5rem" }} />

                <div className="text-sm leading-6">
                  <p className="font-semibold text-gray-900">
                    <p>
                      {/* <span className="absolute inset-0" /> */}
                      <AccountCircle /> {delivery.staff1_name}
                    </p>
                  </p>
                  <p className="text-gray-600">{delivery.bowser_reg_no}</p>
                </div>

                {/* {delivery.chalan_image && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenDialog(true)}
                  >
                    Show Chalan
                  </Button>
                )} */}

                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                  <DialogTitle>Chalan Image</DialogTitle>
                  <DialogContent>
                    <img
                      src={delivery?.chalan_image}
                      alt="Delivery"
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                      }}
                    />
                  </DialogContent>
                </Dialog>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}
